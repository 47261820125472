import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import throttle from 'lodash.throttle';
import contestReducer from './contestSlice';
import errorReducer from './errorSlice';
import { loadState, saveState } from '../utils/localStorage';

const STATE_KEY = 'dragonchain-contests';

const persistedState = loadState(STATE_KEY);

const store = configureStore({
  reducer: {
    contest: contestReducer,
    error: errorReducer,
  },
  preloadedState: persistedState,
  middleware: [
    ...getDefaultMiddleware({
      serializableCheck: false,
    }),
  ],
});

store.subscribe(
  throttle(() => {
    saveState(STATE_KEY, store.getState());
  }, 1000),
);

export default store;
