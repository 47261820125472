import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Axios from 'axios';
import defaultContestState from './contestSchema.json';

const NETWORK_ORDER = ['btc', 'eth'];

export const initializeContest = createAsyncThunk('contest/init', async ({ name }) => {
  const count = await Axios.get(`${process.env.CONTEST_API}/v1/entries/count/${name}`);
  const schema = await Axios.get(`${process.env.CONTEST_API}/v1/form/${name}`);
  return { count: count.data.count, schema: schema.data };
});

export const getCount = createAsyncThunk('contest/count', async ({ name }) => {
  const count = await Axios.get(`${process.env.CONTEST_API}/v1/entries/count/${name}`);
  return count.data.count;
});

export const getWinner = createAsyncThunk('contest/winner', async ({ name }) => {
  const response = await Axios.get(`${process.env.CONTEST_API}/v1/contest/selected/${name}`);
  return response.data.selected[0];
});

export const getEntries = createAsyncThunk('contest/entries', async ({ name }) => {
  const response = await Axios.get(`${process.env.CONTEST_API}/v1/entries/${name}`);
  return response.data;
});

export const newEntry = createAsyncThunk('contest/entry', async ({ form, fields }) => {
  try {
    const entry = await Axios.post(`${process.env.CONTEST_API}/v1/entries`, { form, fields });
    return entry.data.transactionId;
  } catch (e) {
    const customError = { name: e.response.data.code, message: e.response.data.error };
    throw customError;
  }
});

export const contestSlice = createSlice({
  name: 'contest',
  initialState: {
    loading: 'idle',
    current: undefined,
    version: 1,
    contests: {},
  },
  reducers: {},

  extraReducers: {
    [initializeContest.pending]: state => {
      state.loading = 'pending';
    },
    [initializeContest.fulfilled]: (state, action) => {
      const { count, schema } = action.payload;
      const id = action.meta.arg.name;
      if (!state.contests[id]) state.contests[id] = { ...defaultContestState };

      // count data
      state.contests[id].count = count;

      // schema data
      delete schema.creator;
      delete schema.version;
      state.contests[id].schema = { ...schema };

      state.loading = 'idle';
    },
    [initializeContest.rejected]: (state, action) => {
      const id = action.meta.arg.name;
      if (!state.contests[id]) state.contests[id] = defaultContestState;
      state.loading = 'idle';
    },

    [newEntry.pending]: state => {
      state.loading = 'pending';
    },
    [newEntry.fulfilled]: (state, action) => {
      const transactionId = action.payload;
      const { form: id, fields } = action.meta.arg;

      // count data
      state.contests[id].count += 1;

      // entry data
      state.contests[id].entry.transactionId = transactionId;
      state.contests[id].entry.entry = fields;
      state.loading = 'idle';
    },
    [newEntry.rejected]: (state, action) => {
      const id = action.meta.arg.form;
      state.contests[id].entry = {
        entry: {},
        transactionId: undefined,
      };
      state.loading = 'idle';
    },

    [getEntries.pending]: state => {
      state.loading = 'pending';
    },
    [getEntries.fulfilled]: (state, action) => {
      const entries = action.payload;
      const id = action.meta.arg.name;

      state.contests[id].entries = entries.entries.map(entry => ({
        transactionId: entry.transactionId,
        ...entry.entry,
      }));
      state.loading = 'idle';
    },
    [getEntries.rejected]: state => {
      if (state.loading === 'pending') {
        state.loading = 'idle';
      }
    },

    [getCount.pending]: state => {
      state.loading = 'pending';
    },
    [getCount.fulfilled]: (state, action) => {
      const id = action.meta.arg.name;
      const count = action.payload;

      state.contests[id].count = count;
      state.loading = 'idle';
    },
    [getCount.rejected]: state => {
      if (state.loading === 'pending') {
        state.loading = 'idle';
      }
    },

    [getWinner.pending]: state => {
      state.loading = 'pending';
    },
    [getWinner.fulfilled]: (state, action) => {
      const winner = action.payload;
      const id = action.meta.arg.name;

      state.contests[id].winner.selected = true;
      state.contests[id].winner.blockHashes = winner.blockHashes;
      state.contests[id].winner.blockHashArray = NETWORK_ORDER.map(network => winner.blockHashes[network]);
      state.contests[id].winner.transactionId = winner.transactionId;
      state.contests[id].winner.entry = {
        data: { ...winner.entry.payload.entry },
        transactionId: winner.entry.header.invoker,
      };
      state.loading = 'idle';
    },
    [getWinner.rejected]: (state, action) => {
      const id = action.meta.arg.name;
      state.contests[id].winner.selected = false;
      state.loading = 'idle';
    },
  },
});

export const { setCurrentContest } = contestSlice.actions;

export default contestSlice.reducer;
